import { db } from "@/main";

export default {
  state: {
    missions: [],
    mission: {
      name: "",
      backOfficeName: "",
      id: "",
      points: 200,
      imageUrl: "",
      createdAt: "",
    },
  },
  mutations: {
    setMissions(state, payload) {
      state.missions = payload;
    },
    addToMissions(state, payload) {
      state.missions.unshift(payload);
    },
    removeFromMissions(state, payload) {
      var index = state.missions
        .map((x) => {
          return x.id;
        })
        .indexOf(payload.id);
      state.missions.splice(index, 1);
    },
    setMission(state, payload) {
      console.log(payload);
      state.mission = payload;
    },
  },
  actions: {
    fetchMissions({ commit }) {
      const docRef = db.collection("missions").orderBy("createdAt", "desc");
      docRef
        .get()
        .then((querySnapshot) => {
          var missions = [];
          querySnapshot.forEach(function (doc) {
            if (doc.exists) {
              missions.push(doc.data());
            }
          });
          commit("setMissions", missions);
          commit("setError", null);
        })
        .catch((error) => {
          commit("setSnack", "");
          console.log(error);

          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    fetchMission({ commit }, id) {
      const docRef = db.collection("missions").doc(id);
      docRef
        .get()
        .then((doc) => {
          var item = doc.data();
          item["id"] = doc.id;
          commit("setMission", item);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    createMission({ commit, dispatch }, data) {
      commit("setLoading", true);
      data.missionInUsergroup;
      const docRef =
        data.missionInUsergroup == null
          ? db.collection("missions").doc(data.payload.id)
          : db
              .collection("usergroups")
              .doc(data.missionInUsergroup.ugid)
              .collection("programs")
              .doc(data.missionInUsergroup.pid)
              .collection("missions")
              .doc(data.payload.id);

      if (data.missionInUsergroup != null) {
        // add attributes needed for scheduled missions
        data.payload["scheduledAt"] = null;
        data.payload["participantsNotified"] = false;
      }

      docRef
        .set(data.payload)
        .then(() => {
          commit("addToMissions", data.payload);
          commit("setSnack", "Mission wurde erstellt!");
          if (data.missionInUsergroup != null) {
            dispatch(
              "fetchAllMissionsOfUsergroup",
              data.missionInUsergroup.ugid
            );
          }
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
        });
    },
    updateMission({ commit }, data) {
      commit("setLoading", true);
      const docRef =
        data.missionInUsergroup == null
          ? db.collection("missions").doc(data.payload.id)
          : db
              .collection("usergroups")
              .doc(data.missionInUsergroup.ugid)
              .collection("programs")
              .doc(data.missionInUsergroup.pid)
              .collection("missions")
              .doc(data.payload.id);

      docRef
        .update(data.payload)
        .then(() => {
          commit("setSnack", "Mission wurde gespeichert!");
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", error);
          commit("setLoading", false);
        });
    },
    deleteMission({ commit }, mission) {
      commit("setLoading", true);
      const docRef = db.collection("missions").doc(mission.id);
      docRef
        .delete()
        .then(() => {
          commit("removeFromMissions", mission);
          commit("setSnack", "Mission wurde entfernt!");
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
        });
    },
    removeMissionFromUsergroup({ commit, dispatch }, data) {
      commit("setLoading", true);

      const docRef = db
        .collection("usergroups")
        .doc(data.ugid)
        .collection("programs")
        .doc(data.pid)
        .collection("missions")
        .doc(data.mid);

      docRef
        .delete()
        .then(() => {
          dispatch("fetchAllMissionsOfUsergroup", data.ugid);
          commit("setSnack", "Mission wurde entfernt!");
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
        });
    },
  },
  getters: {
    missions(state) {
      return state.missions;
    },
    mission(state) {
      return state.mission;
    },
  },
};
