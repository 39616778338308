import { db, functions } from "@/main";
import firebase from "firebase/app";
export default {
  state: {
    usergroups: [],
    usergroup: {
      name: "",
      description: "",
      participantCount: 0,
      programCount: 2,
      createdAt: "",
    },
    usersOfUsergroup: [],
    missionOfUsergroup: null,
    allMissionsOfUsergroup: [],
    allUniqueUsers: [],
  },
  mutations: {
    setUsergroups(state, payload) {
      state.usergroups = payload;
    },
    setUsergroup(state, payload) {
      state.usergroup = payload;
    },
    updateUsergroup(state, payload) {
      state.usergroup = payload;
    },
    setUsersOfUsergroup(state, payload) {
      state.usersOfUsergroup = payload;
    },
    setAllMissionsOfUsergroup(state, payload) {
      state.allMissionsOfUsergroup = payload;
    },
    setMissionOfUsergroup(state, payload) {
      state.missionOfUsergroup = payload;
    },
    removeUserFromUsergroupList(state, payload) {
      var index = state.usersOfUsergroup
        .map((x) => {
          return x.email;
        })
        .indexOf(payload.email);
      state.usersOfUsergroup.splice(index, 1);
    },
    setAllUniqueUsers(state, payload) {
      state.allUniqueUsers = payload;
    },
  },
  actions: {
    fetchUsergroups({ commit }) {
      commit("setUsergroup", {});
      commit("setAllMissionsOfUsergroup", []);
      const docRef = db.collection("usergroups").orderBy("createdAt", "desc");
      docRef
        .get()
        .then((querySnapshot) => {
          var usergroup = [];
          querySnapshot.forEach(function(doc) {
            var item = doc.data();
            item["id"] = doc.id;
            usergroup.push(item);
          });
          commit("setUsergroups", usergroup);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    fetchUsergroup({ commit }, usergroupId) {
      commit("setAllMissionsOfUsergroup", []);
      commit("setUsergroup", {});
      const docRef = db.collection("usergroups").doc(usergroupId);
      docRef
        .get()
        .then((doc) => {
          var item = doc.data();
          item["id"] = doc.id;
          commit("setUsergroup", item);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    createUsergroup({ dispatch, commit }, payload) {
      commit("setLoading", true);
      const docRef = db.collection("usergroups");
      docRef
        .add(payload)
        .then(() => {
          dispatch("fetchUsergroups");
          commit("setSnack", "Nutzergruppe wurde erstellt!");
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");

          commit("setLoading", false);
        });
    },
    updateUsergroup({ dispatch, commit }, payload) {
      commit("setLoading", true);
      const docRef = db.collection("usergroups").doc(payload.id);
      docRef
        .set(payload, { merge: true })
        .then(() => {
          dispatch("fetchUsergroup", payload.id);
          commit("setSnack", "Nutzergruppe wurde gespeichert!");
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");

          commit("setLoading", false);
        });
    },
    deleteUsergroup({ commit }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        const docRef = db.collection("usergroups").doc(payload);
        docRef
          .delete()
          .then(() => {
            commit("setSnack", "Nutzergruppe wurde gelöscht!");
            commit("setLoading", false);
            commit("setError", null);
            resolve();
          })
          .catch((error) => {
            console.log("Error getting document:", error);
            commit("setSnack", "");
            reject();
            commit("setLoading", false);
          });
      });
    },
    removeUserFromUsergroup({ commit }, payload) {
      commit("setLoading", true);
      const docRef = db
        .collection("usergroups")
        .doc(payload.ugid)
        .collection("participants")
        .doc(payload.user.email);
      docRef
        .delete()
        .then(() => {
          commit("removeUserFromUsergroupList", payload.user);
          commit("setSnack", "Nutzer wurde aus der Nutzergruppe entfernt!");
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
        });
    },
    fetchUsersOfUsergroup({ commit }, usergroupId) {
      const docRef = db
        .collection("usergroups")
        .doc(usergroupId)
        .collection("participants")
        .orderBy("points", "desc");
      docRef
        .get()
        .then((querySnapshot) => {
          var users = [];
          querySnapshot.forEach(function(doc) {
            var item = doc.data();
            item["id"] = doc.id;
            users.push(item);
          });
          commit("setUsersOfUsergroup", users);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    scheduleMission({ state, dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        const docRef = db
          .collection("usergroups")
          .doc(data.ugid)
          .collection("programs")
          .doc(data.pid)
          .collection("missions")
          .doc(data.mid);
        docRef
          .set({ scheduledAt: data.scheduledAt }, { merge: true })
          .then(() => {
            commit("setLoading", false);
            commit("setError", null);
            // set new date localy
            var programs = state.allMissionsOfUsergroup.programs;
            var programIndex = programs.findIndex((item) => {
              return item.id === data.pid;
            });
            if (programIndex >= 0) {
              var missions = programs[programIndex].missions;
              var missionsIndex = missions.findIndex((item) => {
                return item.id === data.mid;
              });
              if (missionsIndex >= 0) {
                var mission = missions[missionsIndex];
                mission["scheduledAt"] = firebase.firestore.Timestamp.fromDate(
                  data.scheduledAt
                );
              }
            } else {
              dispatch("fetchAllMissionsOfUsergroup", data.ugid);
            }

            resolve();
          })
          .catch((error) => {
            console.log(error);
            commit("setSnack", "");
            commit("setLoading", false);
            commit(
              "setError",
              "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
            );
            reject();
          });
      });
    },

    getMissionToAnswer({ commit }, data) {
      commit("setLoading", true);
      var getMissionToAnswer = functions.httpsCallable("getMissionToAnswer");
      getMissionToAnswer(data)
        .then((result) => {
          commit("setLoading", false);
          commit("setError", null);
          var sanitizedMessage = result.data;
          console.log(sanitizedMessage);
          commit("setMissionOfUsergroup", sanitizedMessage);
        })
        .catch((err) => {
          commit("setSnack", err);
          commit(
            "setError",
            "Diese Mission konnte nicht geladen werden oder Du hast sie schon bearbeitet. Wende Dich an Deinen Ansprechpartner oder versuche es später nocheinmal."
          );
          console.log(err);

          commit("setLoading", false);
        });
    },

    async fetchAllMissionsOfUsergroup({ commit }, usergroupId) {
      commit("setAllMissionsOfUsergroup", []);
      commit("setLoading", true);

      try {
        const userGroupRef = db.collection("usergroups").doc(usergroupId);
        const programSnapshots = await userGroupRef
          .collection("programs")
          .orderBy("assignedAt", "desc")
          .get();
        let programs = [];

        programSnapshots.forEach((programSnapshot) => {
          const { name, assignedAt, description } = programSnapshot.data();
          programs.push({
            id: programSnapshot.id,
            name: name,
            assignedAt: assignedAt,
            description: description,
          });
        });

        await Promise.all(
          programs.map(async (program) => {
            // get mission snapshots
            const missionSnapshots = await userGroupRef
              .collection("programs")
              .doc(program.id)
              .collection("missions")
              .orderBy("createdAt", "desc")
              .get();
            let missions = [];

            missionSnapshots.forEach((missionSnapshot) => {
              missions.push({
                id: missionSnapshot.id,
                ...missionSnapshot.data(),
              });
            });

            program["missions"] = missions;

            return;
          })
        );

        commit("setLoading", false);
        commit("setError", null);

        commit("setAllMissionsOfUsergroup", {
          ugid: usergroupId,
          programs: programs,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setSnack",
          "Da hat etwas nicht funktioniert. Bitte versuche es später nochmal."
        );
      }
    },

    provideAnswerToMission({ commit }, data) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        var addMessage = functions.httpsCallable("provideAnswerToMission");
        addMessage(data)
          .then((result) => {
            var sanitizedMessage = result.data;
            console.log(sanitizedMessage);
            commit("setLoading", false);
            commit("setError", null);
            resolve();
          })
          .catch((err) => {
            commit("setSnack", err);
            commit("setLoading", false);
            reject();
          });
      });
    },

    addUserOfUsergroup({ commit, dispatch }, data) {
      commit("setLoading", true);
      var addMessage = functions.httpsCallable("addParticipantsToUserGroup");
      addMessage(data)
        .then(function(result) {
          var sanitizedMessage = result.data;
          console.log(sanitizedMessage);
          commit("setLoading", false);
          commit("setError", null);
          dispatch("fetchUsersOfUsergroup", data.ugid);
        })
        .catch((err) => {
          commit("setSnack", err);
          commit("setLoading", false);
        });
    },

    editUserInUsergroup({ commit }, { ugid, user }) {
      commit("setLoading", true);
      const userRef = db
        .collection("usergroups")
        .doc(ugid)
        .collection("participants")
        .doc(user.id);
      userRef
        .set(user, { merge: true })
        .then(() => {
          commit("setSnack", "Nutzer wurde aktualisiert!");
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", error);
          commit("setLoading", false);
        });
    },
    async assignProgramToUsergroup({ dispatch, commit }, { pid, ugid }) {
      commit("setLoading", true);

      try {
        const userGroupRef = db.collection("usergroups").doc(ugid);
        const programRef = db.collection("programs").doc(pid);

        // checking if usergroup exists
        const usergroupSnapshot = await userGroupRef.get();
        if (!usergroupSnapshot.exists) {
          throw new Error(
            `Die Nutzergruppe wurde nicht gefunden. Bitte versuche es später nochmal.`
          );
        }

        // get program if existing
        const programSnapshot = await programRef.get();
        if (!programSnapshot.exists) {
          throw new Error(
            `Das Programm wurde nicht gefunden. Bitte versuche es später nochmal.`
          );
        }
        const program = programSnapshot.data();
        const missions = [];

        await Promise.all(
          program.missionIds.map(async (missionId) => {
            // get mission
            const missionSnapshot = await db
              .collection("missions")
              .doc(missionId)
              .get();
            if (!missionSnapshot.exists) {
              return;
            }

            // add mission to array to be saved in usergroup later
            missions.push({ originId: missionId, ...missionSnapshot.data() });

            return;
          })
        );

        // check if program has missions
        if (missions.length === 0) {
          throw new Error(
            `Dem Programm sind keine Missionen zugeordnet. Es muss mindestens eine Mission zugeordnet werden.`
          );
        }

        // ref of program in usergroup
        const usergroupProgramRef = userGroupRef.collection("programs").doc();

        // copy program and mission to usergroup
        // Get a new write batch
        let batch = db.batch();

        // increment program count in usergroup
        batch.set(
          userGroupRef,
          { programCount: firebase.firestore.FieldValue.increment(1) },
          { merge: true }
        );

        // add originId to program to reference origin of program in usergroup
        program["originId"] = pid;
        program["assignedAt"] = firebase.firestore.FieldValue.serverTimestamp();

        // copy program
        batch.set(usergroupProgramRef, program);

        // copy all missions of program to usergroup
        missions.forEach((mission) => {
          // populate with additional data for email schedule
          mission["scheduledAt"] = null;
          mission["participantsNotified"] = false;

          const missionRef = usergroupProgramRef.collection("missions").doc();
          mission["id"] = missionRef.id;
          batch.set(missionRef, mission);
        });

        // Commit the batch
        await batch.commit();

        commit("setLoading", false);
        commit("setError", null);
        dispatch("fetchAllMissionsOfUsergroup", ugid);
      } catch (err) {
        commit("setSnack", err);
        commit("setLoading", false);
      }
    },
    addMissionsToProgramInUsergroup({ commit, dispatch }, data) {
      commit("setLoading", true);
      let batch = db.batch();

      data.missions.forEach((mission) => {
        const ref = db
          .collection("usergroups")
          .doc(data.ugid)
          .collection("programs")
          .doc(data.pid)
          .collection("missions")
          .doc(mission.id);
        batch.set(ref, mission);
      });
      batch
        .commit()
        .then(() => {
          commit("setLoading", false);
          commit(
            "setSnack",
            "Mission wurde der dem Program in der Nutzergruppe hinzugefügt"
          );
          dispatch("fetchAllMissionsOfUsergroup", data.ugid);
        })
        .catch((err) => console.error(err));
    },
    setUsernameOfUserOfUsergroup({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        var addMessage = functions.httpsCallable("setUsername");
        addMessage(data)
          .then((result) => {
            var sanitizedMessage = result.data;
            console.log(sanitizedMessage);
            commit("setSnack", "Deine Pseudonym wurde gesetzt.");
            commit("setError", null);
            commit("setLoading", false);
            resolve(result.data);
          })
          .catch((err) => {
            commit("setLoading", false);
            commit("setSnack", "");
            reject(err);
          });
      });
    },
    fetchUniqueUsers({ commit }) {
      commit("setAllUniqueUsers", []);

      const docRef = db.collectionGroup("participants");
      docRef
        .get()
        .then((querySnapshot) => {
          const users = [];

          querySnapshot.forEach(function(doc) {
            users.push(doc.id);
          });

         

          commit("setAllUniqueUsers", [...new Set(users)]);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
  },
  getters: {
    usergroups(state) {
      return state.usergroups;
    },
    allUsers(state) {
      return state.allUniqueUsers;
    },
    usergroup(state) {
      return state.usergroup;
    },
    usersOfUsergroup(state) {
      return state.usersOfUsergroup;
    },
    missionsOfUsergroup(state) {
      return state.allMissionsOfUsergroup.programs;
    },
    missionOfUsergroup(state) {
      return state.missionOfUsergroup;
    },
  },
};
