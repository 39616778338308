<template lang="pug">
div
  v-dialog(v-model='dialog' persistent='' max-width='600px')
    template(v-slot:activator='{ on }')
      v-btn(icon='', color='primary', v-on='on', @click="missionChosen" v-if='editMission')
        v-icon(left='') edit
      
      v-btn(depressed='' color='primary' @click="missionChosen" v-else v-on="on")
        v-icon(left='') mdi-plus
        |           Neue Mission
    
    v-card
      v-img.white--text.align-end(gradient='to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)' contain :src='imageUrl' v-if='imageUrl')
        v-card-title(v-text="mission.name || 'Neue Mission'")
      v-card-title(v-else='')
        span.headline Neue Mission
      v-card-text
        v-container
          v-file-input(v-model='file' @change='onFilePicked' label='Bild' placeholder='Wähle ein Bild aus' prepend-icon='mdi-camera' :rules='rules' accept='image/png, image/jpeg, image/bmp' outlined='' :show-size='1000')
          v-text-field(outlined='' v-model='mission.name' label='Titel' required='')
          v-text-field(outlined='' v-model='mission.backOfficeName' label='Back-Office-Name')
          v-text-field(outlined='' v-model='mission.points' type="number" label='Punkte' required='')
      v-card-actions
        v-spacer
        v-btn(:disabled='loading' :loading='loading' text='' @click='dialog = false') Abbrechen
        v-btn(depressed='', color='primary', :disabled='loading', :loading='loading', @click='submitMission', v-text="editMission ? 'Mission speichern' : 'Mission erstellen'")
        //- v-btn(depressed='', color='primary', v-else='', :disabled='loading', :loading='loading', @click='upload') Mission erstellen


  
</template>
<script>
import firebase from "firebase/app";
import { uuid } from 'vue-uuid';

export default {
  props: ['editMission', 'missionInUsergroup'],
  data: () => ({
    dialog: false,
    file: null,
    photo: null,
    photo_url: null,
    imageName: "",
    imageUrl: "",
    imageFile: "",
    rules: [
      value =>
        !value || value.size < 3000000 || "Image size should be less than 3 MB!"
    ]
  }),
  computed: {
      loading() {
      return this.$store.getters.loading;
    },
     user() {
      return this.$store.getters.user;
    },
    mission: {
      get() {
        return this.$store.getters.mission || "";
      },
      set(value) {
        this.$store.commit("setMission", value);
      }
    }
  },
  methods: {
    missionChosen() {
      console.log(this.editMission);
      if(this.editMission){
        this.mission = this.editMission;
        this.imageUrl = this.editMission.imageUrl;
      }else{
        this.mission = {
        name: "",
        backOfficeName: "",
        id: "",
        points: 200,
        imageUrl: "",
        createdAt: ""
    };
      }
    },
    onFilePicked() {
      const file = this.file;
      if (file !== null) {
        this.imageName = file.name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = file;
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    async submitMission() {
      this.$store.commit('setLoading', true)
      var storageRef = firebase.storage().ref()
      const missionId = this.mission.id == "" ? uuid.v1() : this.mission.id;
      var mountainsRef = this.missionInUsergroup != null  
        ? storageRef.child(`usergroups/${this.missionInUsergroup.ugid}/missions/${missionId}/${this.imageName}`) 
        : storageRef.child(`missions/${missionId}/${this.imageName}`)
      const payload = {
        id: missionId,
        name: this.mission.name,
        backOfficeName: this.mission.backOfficeName,
        imageUrl: this.mission.imageUrl,
        points: Number(this.mission.points),
        updatedAt: new Date()
      }
      if (this.imageFile) {
        const uploadImage = await mountainsRef.put(this.imageFile)
        const imageUrl = await uploadImage.ref.getDownloadURL()
        payload.imageUrl = imageUrl
      }
      if(this.editMission){
        payload["updatedAt"] = new Date()
        payload["updatedBy"] = {
          displayName: this.user.displayName,
          uid: this.user.uid
        }
        this.$store.dispatch("updateMission", {missionInUsergroup: this.missionInUsergroup , payload})
      }else{
        payload["createdAt"] = new Date();
        payload["createdBy"] = {
          displayName: this.user.displayName,
          uid: this.user.uid
        }
        this.$store.dispatch("createMission", {missionInUsergroup: this.missionInUsergroup , payload})
      }
      this.dialog = false
    }
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 100% !important;
  border-radius: 4px;
}
</style>
