<template lang="pug">
  div
    v-container
      v-row
        v-col(cols='12')
          div(v-if='error')
            v-card-title {{error}}
          create-new-usergroup(v-else='')
        v-col(cols='12' md='6' v-for='(usergroup, i) in usergroups' :key='i')
          v-card.mx-auto.d-flex.flex-column(tile='' :to="{ name: 'nutzergruppe', params: { ugid: usergroup.id} }")
            
            v-card-title.headline {{usergroup.name}}
            v-card-subtitle.flex-grow-1 {{usergroup.description}}
            v-list.transparent
              v-list-item
                v-list-item-title Teilnehmer
                v-list-item-avatar.text-right(color="accent lighten-1")
                  span.white--text {{usergroup.participantCount || 0}}
              
              v-list-item
                v-list-item-title Zugewiesene Programme
                v-list-item-avatar.text-right(color="accent")
                  span.white--text {{usergroup.programCount || 0}}
              v-divider.mt-3
              v-list-item
                v-list-item-title.text--disabled Erstellt am {{convertcreatedAt(usergroup.createdAt)}}



</template>

<script>
import CreateNewUsergroup from "@/components/CreateNewUsergroupDialog.vue";
import moment from 'moment';
export default {
  name: "usergroups",
  components: {
    CreateNewUsergroup
  },
  data: () => ({}),
  computed: {
    usergroups() {
      console.log(this.$store.getters.usergroups);
      
      return this.$store.getters.usergroups || [];
    },
    error() {
      return this.$store.getters.error;
    },
  },
  mounted() {
    this.getUsergroups();
  },
  methods: {
    getUsergroups() {
        this.$store.dispatch("fetchUsergroups");
    },
    convertcreatedAt(createdAt) {
      moment.locale('de');
      return moment(createdAt.toDate()).format('lll');
    }
  }
};
</script>
<style lang="sass">
a.v-card 
  transition: all 0.4s ease
  &:hover
    transition: all 0.4s ease
    transform: scale(1.03)
</style>