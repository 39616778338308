<template lang="pug">  
  v-card(class="mx-auto" max-width="750" v-if="mission" )
      v-img(:src="mission.imageUrl")
      v-card-title {{mission.name}}
      v-card-text 
          v-textarea(outlined v-if="!message" v-model="answer" label="Antwort")
          span(v-else v-text="message")
      v-card-actions(class="pb-4 mb-4")
          v-spacer 
          v-btn(color="primary" v-if="!message" :loading="loading" :disabled="answer.length < 10 || loading" @click="completeMission") Abschicken
        
          
</template>
<script>
export default {
  name: "complete-mission",
  data: () => ({
    answer: "",
    message: ""
  }),
  computed: {
    ugid() {
      return this.$route.query.ugid;
    },
    mid() {
      return this.$route.query.mid;
    },
    pid() {
      return this.$route.query.pid;
    },
    username() {
      return this.$route.query.username;
    },
    email() {
      return this.$route.query.email;
    },
    loading() {
      return this.$store.getters.loading;
    },
    error() {
      return this.$store.getters.error;
    },
    mission() {
      return this.$store.getters.missionOfUsergroup;
    }
  },
  created() {
    this.getMissionOfUsergroup();
  },
  methods: {
    getMissionOfUsergroup() {
      var data = {
        ugid: this.ugid,
        mid: this.mid,
        pid: this.pid,
        email: this.email,
        username: this.username
      };

      console.log(data);
      this.$store.dispatch("getMissionToAnswer", data);
      // this.$store
      //   .dispatch("checkAvailability", data)
      //   .then(res => {
      //     console.log(res);
      //     this.message = "Du hast diese Aufgabe schon erledigt.";
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    completeMission() {
    var data = {
       ugid: this.ugid,
        mid: this.mid,
        pid: this.pid,
        email: this.email,
        username: this.username,
        answer: this.answer
      };     
      this.$store.dispatch("provideAnswerToMission", data).then(() => {
          this.message = "Vielen Dank für die Antwort. Wir werden uns in Kürze bei Dir melden."
      }).catch(() => {
          console.log("Something went wrong...");
          
      });

    }
  }
};
</script>
