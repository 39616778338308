<template lang="pug">
  v-container
    ask-for-permission-dialog(v-model="deleteDialog")
    v-row
        v-col(cols='12' md='8')
            v-card.mx-auto(tile='' )
                v-card-title.headline {{ usergroup.name }}
                v-card-subtitle {{ usergroup.description }}
                v-card-actions
                    v-chip(small='' v-if='usergroup.createdAt') {{ convertcreatedAt(usergroup.createdAt) }}
                    v-spacer
                    create-new-usergroup(:edit="true")
                    v-btn(icon='' color='grey darken-1' @click="deleteUsergroup")
                        v-icon delete
            .text-center.mt-4
              v-progress-circular(indeterminate v-if="!missionsOfUsergroup && loading")
            div(v-for='(program, i) in missionsOfUsergroup' :key='i')
                v-row
                  v-col(cols='12')
                    v-card(style="border-top: 4px solid #f77236")
                      v-list-item
                        v-list-item-content
                          v-list-item-title.pl-0.v-card__title(v-text='program.name')
                          v-list-item-subtitle(v-text='convertDate(program.assignedAt)')
                        v-list-item-action
                          create-new-mission-dialog(:missionInUsergroup="{ugid: ugid, pid: program.id}")
                
                  v-col(cols="12" md="6" v-for="(mission, index) in program.missions" :key="mission.id + `-` + index")
                    v-card.mx-auto.d-flex.flex-column(height='100%')
                      v-img.white--text.align-start.pt-4.pl-4.flex-grow-0.grey.lighten-2(contain lazy-src="~@/assets/lazy-loading-tiny.jpg" :src='mission.imageUrl')
                        template(v-slot:placeholder='')
                          v-row.fill-height.ma-0(align='center' justify='center')
                            v-progress-circular(indeterminate='' color='grey lighten-5')
                        
                      v-card-title.flex-grow-1.align-start {{mission.name}}
                      v-card-subtitle.flex-grow-1.align-start {{mission.backOfficeName}}
                      v-card-actions.flex-grow-0
                        schedule-mission-dialog(:variables="{pid: program.id, mid: mission.id, notified: mission.participantsNotified, scheduledAt: mission.scheduledAt}")
                      v-divider.mx-4.flex-grow-0
                      v-card-actions.flex-grow-0
                        v-chip(dark='' color='primary')
                          strong {{mission.points || 0}}
                          span P
                        v-spacer
                        create-new-mission-dialog(v-if="!mission.participantsNotified" :editMission="mission", :missionInUsergroup="{ugid: ugid, pid: program.id}")
                        v-btn(v-if="!mission.participantsNotified" icon='' color='grey darken-1' @click="removeMission(program.id, mission)")
                            v-icon delete
                  
        v-col(cols="12" md="4")
          add-user-to-usergroup
          add-program-to-usergroup
          v-card.mx-auto(tile='')
            v-list(shaped='' class="flex-grow-1")
              v-subheader Teilnehmer
              v-list-item(v-if="usersOfUsergroup.length < 1")
                v-list-item-subtitle Keine Teilnehmer zugeordnet...
              template(v-for='(user, i) in usersOfUsergroup')
                v-list-item(two-line='' :key='user.id')
                  v-list-item-avatar(color='accent-4' :color="user.username ? 'accent' : 'grey lighten-1'")
                      v-icon(color="white") check
                  v-list-item-content
                      v-list-item-title(v-if="user.username") {{user.username}}
                      v-list-item-subtitle(v-text='user.email')
                  v-list-item-action
                    .overline {{user.points}}P
                    edit-user-in-usergroup(:user="user")
                    //-v-btn(small icon color="grey lighten-2" @click="removeUserFromUsergroup(user)") 
                      v-icon edit
                v-divider(v-if='i + 1 < usersOfUsergroup.length', :key='i')
                  
                    
            v-footer(color="grey lighten-1 flex-grow-0 text-center mt-3" ) 
              v-btn(disabled block text) {{convertcreatedAt(usergroup.createdAt)}}
        
    
</template>
<script>
import CreateNewMissionDialog from "@/components/CreateNewMissionDialog.vue";
import AddMissionToProgramInUsergroup from "@/components/AddMissionToProgramInUsergroupDialog.vue";
import AddProgramToUsergroup from "@/components/AddProgramToUsergroupDialog.vue";
import AddUserToUsergroup from "@/components/AddUserToUsergroupDialog.vue";
import EditUserInUsergroup from "@/components/EditUserInUsergroupDialog.vue";
import AskForPermissionDialog from "@/components/AskForPermissionDialog.vue";
import CreateNewUsergroup from "@/components/CreateNewUsergroupDialog.vue";
import ScheduleMissionDialog from "@/components/ScheduleMissionDialog.vue";
import moment from "moment";
export default {
  name: "usergroup",
  components: {
    CreateNewMissionDialog,
    AddMissionToProgramInUsergroup,
    AddProgramToUsergroup,
    AddUserToUsergroup,
    CreateNewUsergroup,
    ScheduleMissionDialog,
    AskForPermissionDialog,
    EditUserInUsergroup
  },
  data: () => ({
    email: "",
    valid: true,
    lazy: false,
    addTaskDialog: false,
    add_task: null,
    e1: 1,
    date_picker: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    time_picker: null,
    rules: {
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    },
    items: [],
    email_addresses: [],
    search: null,
    deleteDialog: {
      show: false
    },
  }),
  watch: {
    email_addresses(val) {
      if (val.length > 10) {
        this.$nextTick(() => this.email_addresses.pop());
      }
    }
  },
  computed: {
    usergroup() {
      return this.$store.getters.usergroup || null;
    },
    usersOfUsergroup() {
      return this.$store.getters.usersOfUsergroup;
    },
    missionsOfUsergroup() {
      return this.$store.getters.missionsOfUsergroup ?? [];
    },
    loading() {
      return this.$store.getters.loading;
    },
    tasks() {
      return this.$store.getters.tasks || [];
    },
    selectedTask() {
      return this.tasks[this.add_task];
    },
    ugid(){
      return this.$route.params.ugid;
    }
  },
  mounted() {
    this.getUsergroup();
  },
  methods: {
    getUsergroup() {
      this.$store.dispatch("fetchUsergroup", this.ugid);
      this.$store.dispatch("fetchUsersOfUsergroup", this.ugid);
      this.$store.dispatch("fetchAllMissionsOfUsergroup", this.ugid);
    },
    deleteUsergroup(){
       this.deleteDialog = {
        show: true,
        text: "Möchtest du die Nutzergruppe '" + this.usergroup.name + "' wirklich löschen?",
        accepted: false,
        button: "Löschen",
        usergroup: this.usergroup
      };
    },
    removeUserFromUsergroup(user){
        const name = user.username || user.email;
       this.deleteDialog = {
        show: true,
        text: "Möchtest du den Nutzer '" + name + "' wirklich von dieser Nutzergruppe entfernen?",
        accepted: false,
        button: "Entfernen",
        user: user
      };
    },
    removeMission(pid, mission){
      console.log(pid, " ", mission);
       this.deleteDialog = {
        show: true,
        text: "Möchtest du die Mission '" + mission.name + "' wirklich löschen?",
        accepted: false,
        button: "Löschen",
        mission: {
          ugid: this.ugid,
          pid,
          mid: mission.id
        }
      };
    },
    deleteMe(){
      if(this.deleteDialog.usergroup){
      this.$store.dispatch("deleteUsergroup", this.ugid).then(() => {
        this.$router.go(-1);
      }).catch(() =>{
        console.log("error deleting");
        
      });
      }else if(this.deleteDialog.user){
        this.$store.dispatch("removeUserFromUsergroup", {ugid: this.ugid, user: this.deleteDialog.user})
      }else if(this.deleteDialog.mission){
        this.$store.dispatch("removeMissionFromUsergroup", this.deleteDialog.mission)
      }
    },
    convertcreatedAt(createdAt) {
      if (createdAt) {
        moment.locale("de");
        return moment(createdAt.toDate()).format("llll");
      } else {
        return "";
      }
    },
    convertDate(createdAt) {
      if (createdAt) {
        moment.locale("de");
        return moment(createdAt.toDate()).format("llll");
      } else {
        return "";
      }
    },
    fromNow(createdAt) {
      console.log(createdAt);
      
      if (createdAt) {
        moment.locale("de");
        return moment(createdAt).fromNow();
      } else {
        return "";
      }
    },
    scheduleText(task) {
      if (task.status == "sent") {
        return (
          "Aufgabe wurde " +
          this.fromNow(task.mails_scheduled_at) +
          " verschickt"
        );
      } else {
        return (
          "Aufgabe wird " +
          this.fromNow(task.mails_scheduled_at) +
          " verschickt"
        );
      }
    },
    
    searchTasks() {
      this.addTaskDialog = true;
      this.$store.dispatch("fetchTasks");
    },
    chooseIcon(status) {
      switch (status) {
        case "scheduled":
          return "schedule";
        case "sent":
          return "send";
        case "cancel_schedule_send":
          return "cancel_schedule_send";
        default:
          return "schedule";
      }
    }
  }
};
</script>
<style lang="sass">
a.v-card
  transition: all 0.4s ease
  &:hover
    transition: all 0.4s ease
    transform: scale(1.03)
</style>
