import firebase from 'firebase/app'
import { functions } from '@/main'
import router from '@/router/index.js'
export default {
  state: {
    user: null,
    employee: {
      email: '',
      firstname: '',
      lastname: ''
    },
    employees: [],

  },
  mutations: {
    setEmployees(state, payload) {
      state.employees = payload
    },
    updateEmployee(state, payload) {
      state.employee = payload
    },
    removeEmployee(state, uid) {
      var index = state.employees.map((x) => { return x.id; }).indexOf(uid);
      state.employees.splice(index, 1);
    },
    setUser(state, payload) {
      state.user = payload
    },
    updateDisplayName(state, payload) {
      state.user.displayName = payload
    },
    updateLastName(state, payload) {
      state.user.lastname = payload
    }
  },
  actions: {
    fetchEmployees({ commit }) {
      commit('setLoading', true)
      var fetchEmployeeFunction = functions.httpsCallable('getEmployees');
      fetchEmployeeFunction().then((result) => {
        console.log(result);
        commit('setEmployees', result.data)
        commit('setLoading', false)
        commit('setError', null)
      }).catch((error) => {
          console.log("Error getting document:", error);
          commit('setSnack', error.message)
          commit('setLoading', false)
          commit('setError', "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen.");
        });
    },
    createEmployee({ dispatch, commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
      var inviteEmployeeFunction = functions.httpsCallable('inviteEmployee');
      inviteEmployeeFunction(payload).then((result) => {

        var sanitizedMessage = result.data;
        console.log(sanitizedMessage);
        dispatch('fetchEmployees');
        commit('setSnack', "Mitarbeiter wurde erstellt!");
        commit('setError', null)
        resolve();
      }).catch((error) => {
        console.log("Error getting document:", error);
        commit('setSnack', error.message)
        commit('setLoading', false)
        reject();
      });
    });
    },
    setAdminStatusOfEmployee({ commit }, payload) {
      commit('setLoading', true)
      var setAdminFunction = functions.httpsCallable('setAdminStatusOfEmployee');
      setAdminFunction(payload).then(function (result) {
        var sanitizedMessage = result.data;
        console.log(sanitizedMessage);
        commit('setSnack', "Nutzerrolle wurde erfolgreich angepasst!");
        commit('setError', null)
        commit('setLoading', false)
      }).catch((error) => {
        console.log("Error getting document:", error);
        commit('setSnack', error.message)
        commit('setLoading', false)
      });
    },
    removeEmployee({ commit }, payload) {
      commit('setLoading', true)
      var removeEmployeeFunction = functions.httpsCallable('removeEmployee');
      removeEmployeeFunction(payload)
      .then((result) => {
        var sanitizedMessage = result.data;
        console.log(sanitizedMessage);
        commit('setSnack', "Nutzer wurd entfernt!");
        commit('removeEmployee', payload);
        commit('setError', null)
        commit('setLoading', false)
      }).catch((error) => {
        console.log("Error getting document:", error);
        commit('setSnack', error.message)
        commit('setLoading', false)
      });
    },
    
    resetPassword({ commit }, payload) {
          commit('setLoading', true);
          firebase.auth().confirmPasswordReset(payload.code, payload.password)
          .then(res => {
              console.log(res);
            commit('setSnack', "Password wurde erfolgreich gesetzt.")
            router.push('/login')
            commit('setLoading', false)
            commit('setError', null)
          })
          .catch(err => {
            console.log(err);
            commit('setSnack', err)
            commit('setLoading', false)
          })
    },
    setPassword({ commit }, payload) {
      commit('setLoading', true);
      var setPasswordFunction = functions.httpsCallable('setPassword');
      setPasswordFunction(payload)
      .then(res => {
          console.log(res);
        commit('setSnack', "Password wurde erfolgreich gesetzt.")
        router.push('/login')
        commit('setLoading', false)
        commit('setError', null)
      })
      .catch(err => {
        console.log(err);
        commit('setSnack', err)
        commit('setLoading', false)
      })
    },
    
    signUserIn({ commit }, payload) {
      commit('setLoading', true)
      commit('clearError')
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then( auth => {
            let user = auth.user;
            auth.user.getIdTokenResult()
              .then(({claims}) => {
                user["customClaims"] = claims
            commit('setUser', user)
            commit('setError', null)
            commit('setLoading', false)
    });
          }
        )
        .catch(
          error => {
            commit('setLoading', false)
            commit('setError', error)
          }
        )
    },
    
    setUserInformations({ commit }, payload) {
      console.log("change to: ", payload);
      var user = firebase.auth().currentUser;

      user.updateProfile({
        displayName: payload.displayName
      }).then(() => {
        commit('setSnack', "Anpassung wurde gespeichert.")
        commit('setLoading', false)
        commit('setError', null)
      }).catch((error) => {
        console.log(error);
        
        commit('setSnack', "")
        commit('setLoading', false)
      });
    },

    
    resetPasswordWithEmail({ commit }, payload) {
      const { email } = payload
      commit('setLoading', true)
      
      var requestPasswordResetLinkFunction = functions.httpsCallable('requestPasswordResetLink');
      requestPasswordResetLinkFunction({email: email}).then((result) => {
        commit('setLoading', false)
        commit('setError', null)
        commit('setSnack', "Wir haben Dir eine E-Mail zum Zurücksetzen des Passworts geschickt.")
        commit('setError', "Wir haben Dir eine E-Mail zum Zurücksetzen des Passworts geschickt.")
        console.log(result);

      }).catch( error => {
        console.log(error);
        commit('setLoading', false)
        if(error.message){
          commit('setError', error.message)
        }else{
          commit('setError', "Da ist leider etwas schiefgelaufen.")
        }
            
      })
    },
    logout({ commit }) {
      firebase.auth().signOut()
      commit('setUser', null)
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    employees(state) {
      return state.employees
    },
    employee(state) {
      return state.employee
    }
  }
}
