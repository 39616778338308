<template lang="pug">
  v-container
    ask-for-permission-dialog(v-model="deleteDialog")
    v-row
        v-col(cols='12' md="8")
            v-card.mx-auto(tile='' )
                v-card-title.headline {{ program.name }}
                v-card-subtitle {{ program.description }}
                v-card-subtitle {{ program.missions }}
                v-card-actions
                    v-chip(small='' v-if='program.createdAt') {{ convertCreatedAt(program.createdAt) }}
                    v-spacer
                    v-btn(icon='' color='grey darken-1' @click="removeProgram(program)")
                        v-icon delete
            v-row()
              v-col(cols='12' md="6" v-for='(mission, i) in missionsOfProgram' v-if="missionsOfProgram && mission" :key='mission.id')
                v-card
                  v-img.white--text.align-end(:src='mission.imageUrl')
                  v-card-title(v-text='mission.name')
                  v-card-subtitle(v-text='mission.backOfficeName')
                  v-card-actions
                    v-chip.mr-2(dark='' color='grey' v-if="mission.createdAt")
                      span {{fromNow(mission.createdAt)}}
                    v-chip(dark='' color='accent')
                      strong {{mission.points || 0}}
                      span P
                    v-spacer
                    v-btn(icon='' color='grey darken-1' @click="removeMissionFromProgram(mission)")
                      v-icon delete
              v-col(cols='12' md="6" v-if="loading")
                div.text-center
                  v-progress-circular.mx-auto(color="primary" indeterminate)
        v-col(cols="12" md="4")
          add-mission-to-program(:missionIds="missionsOfProgram")
    
</template>
<script>
import AddMissionToProgram from "@/components/AddMissionToProgramDialog.vue";
import AskForPermissionDialog from "@/components/AskForPermissionDialog.vue";
import moment from "moment";
export default {
  name: "program",
  components: {
    AddMissionToProgram,
    AskForPermissionDialog
  },
  data: () => ({
    deleteDialog: {
      show: false
    },
    
  }),
  computed: {
    program() {
      return this.$store.getters.program || null;
    },
    usersOfUsergroup() {
      return this.$store.getters.usersOfUsergroup;
    },
    missionsOfProgram() {
      return this.$store.getters.missionsOfProgram.filter(item => item !== undefined);
    },
    loading() {
      return this.$store.getters.loading;
    },
    tasks() {
      return this.$store.getters.tasks || [];
    },
    selectedTask() {
      return this.tasks[this.add_task];
    }
  },
  mounted() {
    this.getProgram();
  },
  methods: {
    getProgram() {
      var pid = this.$route.params.pid;
      this.$store.dispatch("fetchProgram", {id: pid});
    },
    removeMissionFromProgram(mission){
      this.deleteDialog = {
        show: true,
        text: "Möchtest Du die Mission '" + mission.name + "' wirklich aus diesem Programm entfernen?",
        accepted: false,
        button: "Entfernen",
        mission: mission
      };
    },
    removeProgram(program){
      this.deleteDialog = {
        show: true,
        text: "Möchtest Du das Programm '" + program.name + "' wirklich löschen?",
        accepted: false,
        button: "Löschen",
        program: program
      };
    },
    deleteMe(){
      if(this.deleteDialog.mission){
      const mission = this.deleteDialog.mission;

      var pid = this.$route.params.pid;
      var filteredMission = this.missionsOfProgram.filter(event => {
          const isSelected = (mission.id === event.id);
          return !isSelected;
        })

      var filteredMissionIds = [];
      filteredMission.forEach(element => {
          console.log("filteredMissionIds: ", element)
          filteredMissionIds.push(element.id);
        });
      const data = {
            missionIds: filteredMissionIds,
            pid
        }
        // console.log(data);
        
        this.$store.dispatch("assignMissionsToProgram", data);
        }else if(this.deleteDialog.program){
          this.$store.dispatch("deleteProgram", this.deleteDialog.program).then(() => {
        this.$router.go(-1);
      }).catch(() =>{
        console.log("error deleting");
        
      });
        }
    },
    convertCreatedAt(createdAt) {
      if (createdAt) {
        moment.locale("de");
        return moment(createdAt.toDate()).format("llll");
      } else {
        return "";
      }
    },
    fromNow(createdAt) {
      if (createdAt) {
        moment.locale("de");
        return moment(createdAt.toDate()).format('lll');
      } else {
        return "";
      }
    }
  }
};
</script>
<style lang="sass">
a.v-card
  transition: all 0.4s ease
  &:hover
    transition: all 0.4s ease
    transform: scale(1.03)
</style>
